<template>
  <div class="author_kol">
    <el-form :model="form" :rules="err" ref="form" label-width="100px" class="form">
      <el-form-item label="头像" prop="picUrl" class="avatar">
        <cd-upload v-model="form.picUrl" :isLogo="true" className="avatars" tips="建议使用一寸证件照70*100像素"
                   :on-success="handleAvatarimageurl"/>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name" placeholder="请输入姓名" class="w400"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="form.sex" placeholder="请选择性别" class="w400">
          <el-option label="男" value="6"></el-option>
          <el-option label="女" value="7"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="自我介绍" prop="contentDesc">
        <el-input type="textarea" v-model="form.contentDesc" placeholder="请输入自我介绍" class="w400"></el-input>
      </el-form-item>
      <div class="button">
        <el-button type="info" @click="handleClose()">取消</el-button>
        <el-button type="primary" @click="kolauthorKOLRegister()">提交</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "kolAuthorEdit",
  inject: ["reload"],
  props: ["type"],
  data() {
    return {
      form: {
        picUrl: '',
        name: '',
        sex: '',
        contentDesc: '',
      },
      err: {
        picUrl: [
          {required: false, message: "头像不能为空", trigger: "change"},
        ],
        name: [
          {required: true, message: "姓名不能为空", trigger: "change"},
        ],
        sex: [
          {required: true, message: "性别不能为空", trigger: "change"},
        ],
        contentDesc: [
          {required: true, message: "自我介绍不能为空", trigger: "change"},
        ],
      },
    }
  },
  methods: {
    // kol作者申请
    kolauthorKOLRegister() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$api.kolauthorKOLRegister(this.form).then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.reload();
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error(valid);
        }

      })

    },
    handleAvatarimageurl(res, file) {
      this.form.picUrl = res.msg;
    },
    erroupload() {
      this.$message({
        message: "图片上传失败，请重新上传！！",
        center: true,
      });
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      console.log(isImage, isLt2M)
      return isImage && isLt2M;
    },
    handleClose() {
      this.$emit('close', false);
    },
  }
}
</script>

<style scoped lang="less">

</style>
